import React from "react";
import ReactPlayer from "react-player/lazy";
import { Heading, HStack, VStack } from "@chakra-ui/react";
import { BrowserView, MobileView } from "react-device-detect";

// Lazy load the YouTube player

function Player() {
  return (
    <>
      <BrowserView>
        <HStack spacing={10}>
          <ReactPlayer url="https://www.youtube.com/watch?v=LboZVSkbiWc" />
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: "xl", sm: "xl", lg: "xl" }}
          >
            BAUTISMO DEL REMOLCADOR TIFON
          </Heading>
        </HStack>
      </BrowserView>
      <MobileView>
        <VStack>
          <ReactPlayer
            width="90%"
            url="https://www.youtube.com/watch?v=LboZVSkbiWc"
          />
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: "xl", sm: "xl", lg: "xl" }}
          >
            BAUTISMO DEL REMOLCADOR TIFON
          </Heading>
          <br />
        </VStack>
      </MobileView>
    </>
  );
}

export default Player;
