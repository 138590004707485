

import {
  Box,
  Container,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import {Logo} from '../assets/IconLogo'

const ListHeader = ({ children } ) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

export default function LargeWithLogoLeft() {
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Container as={Stack} maxW={'6xl'} py={10}>
        <SimpleGrid
          templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr' }}
          spacing={8}>
          <Stack spacing={4}>
            <Box>
              <Logo width='150px' height='150px' />
            </Box>
            <Text fontSize={'sm'}>
              © 2022 an UItech Web App.
            </Text>
          </Stack>
      
          <Stack align={'flex-start'}>
          <ListHeader>Datos</ListHeader>
            <Text fontSize={'md'}>
            Guido Spano N° 2664 c/ Denis Roa 
            <br/>
            Tel: +595 21 497 948 / 601 609 
            <br/>
           
            pedidos@redpar.com.py 
            <br/>
            www.redpar.com.py
            <br/>

            </Text>
          </Stack>
          <Stack align={'flex-start'}>

            <ListHeader>Contacto</ListHeader>

            <Text fontSize={'md'}>
            Gabriel Acuña 
            <br/>
            Cel: +595 992 207 729
            <br/>
             gabriel.acuna@redpar.com.py
            </Text>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
}