
import {Image} from '@chakra-ui/react'

// 2. Use the `as` prop
export const Logo = ({height, width}
) =>{
  return <Image borderRadius='full'
h={height}
w={width}
p={0}
  alt='Logo RedPar' src='/images/nobgRedPar.png'/>
}