
import { Routes, Route } from 'react-router-dom'
import Simple from "./components/NavBar";
import QuienesSomos from "./components/QuienesSomos";
import Servicios from "./components/Servicios";
import Footer from "./components/Footer";
import Galery from "./components/Galery";

function App() {
  return (
    <div className="App">
      <Simple />
      <Routes >
        <Route path='/' element={<QuienesSomos />} />
        <Route path='/servicios' element={<Servicios />} />
        <Route path='/galeria' element={<Galery />} />
        <Route path='/contacto' />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
