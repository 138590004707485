import {
  Box,
  Container,
  Stack,
  Image,
  Flex,
  Heading,
  SimpleGrid,
  StackDivider,
} from "@chakra-ui/react";

import Player from "./Player";
const ServicesList = [
  {
    title: " TRABAJOS DE LIMPIEZA Y DESGASIFICACION",
    img: "/images/degasificacion.jpeg",
  },
  {
    title: "CONSTRUCCION DE EMBARCACIONES NUEVAS",
    img: "/images/construccion.jpeg",
  },
  { title: "SALVAMENTO Y BUCEO", img: "/images/buceo.jpeg" },
  {
    title: "PINTURA DE BARCAZAS Y TAPAS ",
    img: "/images/pintadodebarcazas2.jpeg",
  },
  {
    title: "PINTURA DE BARCAZAS Y TAPAS ",
    img: "/images/pintadodebarcazas.jpeg",
  },
];
const features = ServicesList.map(function (obj, i) {
  return {
    id: i,
    title: `${obj.title}`,
    img: `${obj.img}`,
  };
});
export default function Galery() {
  return (
    <>
      <br />
      <Container maxW={"7xl"}>
        <Flex alignItems={"center"} spacing={{ base: 8, md: 10 }}>
          <Player />
        </Flex>
        <SimpleGrid
          columns={{ base: 1, lg: 2 }}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 18, md: 24 }}
        >
          {features.map((feature) => (
            <>
              <Flex>
                <Image
                  rounded={"md"}
                  alt={"product image"}
                  src={feature.img}
                  fit={"cover"}
                  align={"center"}
                  w={"100%"}
                  h={{ base: "100%", sm: "300px", lg: "400px" }}
                />
              </Flex>
              <Stack spacing={{ base: 6, md: 10 }}>
                <Box as={"header"}>
                  <Heading
                    lineHeight={1.1}
                    fontWeight={600}
                    fontSize={{ base: "xl", sm: "xl", lg: "xl" }}
                  >
                    {feature.title}
                  </Heading>
                </Box>

                <Stack
                  spacing={{ base: 4, sm: 6 }}
                  direction={"column"}
                  divider={<StackDivider borderColor="gray.200" />}
                ></Stack>
              </Stack>
            </>
          ))}
        </SimpleGrid>
      </Container>
    </>
  );
}
