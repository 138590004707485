import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

const ServicesList = [
  {
    title: "SEGURIDAD INDUSTRIAL",
    text: "• Sistema de gestión ocupacional y ambiente (SOA) • Técnico de seguridad permanente.",
  },
  {
    title: "SERVICIOS OPERATIVOS",
    text: "• Remolcador portuario • Control de carga • Tapado y destapado de barcazas • Servicios de Bomberos.",
  },
  {
    title: " SERVICIOS DE INGENIERÍA",
    text: "• Anteproyectos y Proyectos de embarcaciones • Modificación y transformación de embarcaciones, • Cálculos estructurales",
  },
  {
    title: "VARADERO PROPIO",
    text: "• Inspección • Reparación • Construcción • Arenado y Pintado.",
  },
  {
    title: "SALVAMENTO Y BUCEO",
    text: "• Inspección y obturación de casco • Reflotamiento de embarcaciones • Búsqueda y rescate de elementos u objetos de navegación • Inspección de órganos propulsores • Liberación de sistema de propulsion • Cambio de empaquetadura de bocina • Relevamiento de fondo • Perforación de elementos neumáticos.",
  },
  {
    title: "TRABAJOS DE MECANICA Y ELECTRICIDAD",
    text: "• Mecanizado, ajuste y armado de bombas, compresores • Recorrido de motores diesel principales y auxiliares • Puestas en marcha y tendido de líneas • Mecanizado de Piezas",
  },
  {
    title: "TRABAJOS DE CALDERERÍA",
    text: "• Renovación de chapas y estructuras de casco • Reparación y construcción de mecanismo de cubierta • Construcción y montaje de tuberías • Construcción de embarcaciones • Soldadores Certificados • Procesos de Soldadura.",
  },
  {
    title: "TRABAJOS DE LIMPIEZA Y DESGASIFICACION",
    text: "Limpieza de tanques y bodegas para cambio de Producto • Limpieza y desgasificado para trabajos en caliente • Hidroarenado y pintado • Retiro de sloop • Limpieza de sentinas • Free gas.",
  },
  {
    title: "TRABAJOS EN FIBRA DE VIDRIO",
    text: "• Reconstrucciones de tapas  Construcción de moldes • Pintados de Tapas.",
  },
  {
    title: "PRUEBAS HIDRÁULICAS",
    text: "Líneas de incendio • Tuberías de cargamento • Botellones de Aire.",
  },
  {
    title: "ENSAYOS NO DESTRUCTIVOS",
    text: "• Ultrasonido: Medición de espesores.",
  },
];
/*






*/

// Replace test data with your own
const features = ServicesList.map(function (obj, i) {
  return {
    id: i,
    title: `${obj.title}`,
    text: `${obj.text}`,
  };
});

export default function Servicios() {
  return (
    <Box p={4}>
      <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
        <Heading fontSize={"3xl"}>Servicios</Heading>
        <Text color={"gray.600"} fontSize={"xl"}>
          Siempre poniendo el foco en nuestros clientes.
        </Text>
      </Stack>

      <Container maxW={"6xl"} mt={10}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
          {features.map((feature) => (
            <HStack key={feature.id} align={"top"}>
              <Box color={"green.400"} px={2}>
                <Icon as={CheckIcon} />
              </Box>
              <VStack align={"start"}>
                <Text fontWeight={600}>{feature.title}</Text>
                <Text color={"gray.600"}>{feature.text}</Text>
              </VStack>
            </HStack>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
}
